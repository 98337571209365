html,
body,
div,
span,
applet,
object,
iframe,
h1,
h2,
h3,
h4,
h5,
h6,
blockquote,
pre,
a,
abbr,
acronym,
address,
big,
cite,
code,
del,
dfn,
em,
font,
ins,
kbd,
q,
s,
samp,
small,
strike,
strong,
sub,
sup,
tt,
var,
dl,
dt,
dd,
ol,
ul,
li,
fieldset,
form,
label,
legend,
table,
caption,
tbody,
tfoot,
thead,
tr,
th,
td {
  border: 0;
  font-style: normal;
  font-weight: normal;
  margin: 0;
  outline: 0;
  padding: 0;
  vertical-align: baseline;
}
html {
  padding: 0;
  margin: 0;
}
body {
  padding: 0;
  margin: 0;
  font-size: 16px;
}
img {
  border: none;
  display: block;
}
p {
  margin: 0;
}
a {
  text-decoration: none;
}
*:focus {
  outline: none;
}
* {
  box-sizing: border-box;
}
li {
  list-style: none;
}
input,
textarea,
button,
select {
  padding: 0;
  margin: 0;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
}
::-webkit-scrollbar {
  -webkit-appearance: none;
  background: #f1f1f1;
}

::-webkit-scrollbar:vertical {
  width: 1vw;
}
::-webkit-scrollbar-button:increment,
.scroll::-webkit-scrollbar-button {
  display: none;
}
::-webkit-scrollbar:horizontal {
  height: 1vw;
}
::-webkit-scrollbar-thumb {
  background-color: #c0c0c0;
  border: 0px solid #f1f1f1;
}
::-webkit-scrollbar-track {
  border-radius: 0px;
}
::placeholder,
::placeholder,
::placeholder {
  color: #a6abc1;
}
:-ms-input-placeholder,
:-ms-input-placeholder,
:-ms-input-placeholder {
  color: #a6abc1;
}
::-ms-input-placeholder,
::-ms-input-placeholder,
::-ms-input-placeholder {
  color: #a6abc1;
}
input[type='checkbox'] {
  width: 0.8vw;
  height: 0.8vw;
  min-width: 0.8vw;
  min-height: 0.8vw;
  margin: 0px;
  cursor: pointer;
  background: #fff;
  border: 1px solid #d2d5e0;
  box-sizing: border-box;
  border-radius: 0.1vw;
  margin-right: 0.5vw;
}
input[type='checkbox']:checked {
  -webkit-appearance: auto;
  -moz-appearance: auto;
  appearance: auto;
}

:root {
  --background-color: #212138;
  --primary-color: #ce0c33;
  --secondary-color: #ec1b2e;
  --green-color: #2dd5ac;
  --hover-green-color: #22cba2;
}

.flexbox {
  display: flex;
  flex-flow: row wrap;
}
.hidden-component {
  display: none;
}

.requiredfield {
  border: 1px solid #eb5757 !important;
}

.requiredtext {
  color: #eb5757 !important;
}

.requiredtext a {
  color: #eb5757 !important;
}

#module-notification {
  display: none;
}

.box-notification {
  width: 23vw;
  position: fixed;
  top: 1vw;
  right: 2vw;
  background: #ffff;
  border-radius: 0.3vw;
  box-shadow: 0 0.5vw 1.5vw #21213829;
  z-index: 99;
  display: flex;
  padding: 1vw 1.5vw;
  align-items: center;
}

.icon-notification {
  border: 0.15vw solid transparent;
  margin-right: 0.7vw;
  border-radius: 0.3vw;
  width: 2.8vw;
  height: 2.8vw;
  background: url('/assets/images/icon-notification.svg') no-repeat center / contain;
}

.warning-notification .icon-notification {
  background: url('/assets/images/icon-warning-notification.svg') no-repeat center / contain !important;
}

.error-notification .icon-notification {
  background: url('/assets/images/icon-error-notification.svg') no-repeat center / contain !important;
}

.information-notification .icon-notification {
  background: url('/assets/images/icon-information-notification.svg') no-repeat center / contain !important;
}

.box-notification div {
  width: 14vw;
}

.box-notification div h2 {
  display: block;
  font: 1vw/1vw poppins-bold;
  color: #212138;
}

.box-notification div span {
  display: block;
  font: 0.85vw/1vw poppins-regular;
  color: #212138;
  padding-top: 0.25vw;
}

.button-close-notification {
  cursor: pointer;
  border: none;
  width: 0.85vw;
  height: 0.85vw;
  background: url('/assets/images/icon-close-notification.svg') no-repeat center / contain;
  position: absolute;
  right: 2vw;
  top: 2vw;
}
#contaniner {
  display: flex;
  align-items: center;
}
@viewport {
  width: device-width;
  zoom: 1;
}

/*
@media screen and (max-width: 1100px)
{
	body 
	{
		font-size:13.75px;
	} 
    .row-form-sign-in, 
	.row-form-login, 
	.row-form-reset-password, 
	.row-form-profile ,
	.row-not-event,
	.row-schedule ,
	.row-successfully-event ,
	.row-edit-schedule ,
	.row-setting ,
	.row-selfie ,
	.row-choose-template ,
	.row-setting-time
	{
		width: 55% !important;
	}
	
}
*/

@media screen and (max-width: 760px) {
  body {
    font-size: 11.5px;
  }

  .btn-back {
    font: 1em / 1em poppins-regular !important;
    background: url('/assets/images/icon-back.svg') no-repeat center left / 0.5em !important;
    padding-left: 1.25em !important;
    left: 2em !important;
    top: 2em !important;
  }
  .box-notification {
    width: 94%;
    top: 2%;
    right: 3%;
    border-radius: 3px;
    padding: 1em 0.5em; 
	font-size: 16px;
  }

  .icon-notification {
    margin-right: 1em;
    width: 2.2em;
    height: 2.2em;
  }

  .box-notification div h2 {
    font: 0.9em/1em poppins-bold;
  }

  .box-notification div {
    width: 15em;
  }

  .box-notification div span {
    font: 0.85em/1em poppins-regular;
    padding-top: 5px;
  }

  .button-close-notification {
    width: 1em;
    height: 1em;
    right: 1.5em;
    top: 2em;
  }

  input[type='checkbox'] {
    width: 1em;
    height: 1em;
    min-width: 1em;
    min-height: 1em;
    margin-right: 0.5em;
  }
  ::-webkit-scrollbar:vertical {
    width: 0.35em !important;
  }
  .row-dots {
    margin-top: 1.875em !important;
  }
  .row-dots button {
    width: 0.5em !important;
    height: 0.5em !important;
    margin: 0 0.25em !important;
  }

  .row-form-sign-in,
  .row-form-login,
  .row-form-reset-password,
  .row-form-profile,
  .row-not-event,
  .row-schedule,
  .row-successfully-event,
  .row-edit-schedule,
  .row-setting,
  .row-selfie,
  .row-choose-template,
  .row-setting-time,
  .row-summary {
    width: 90% !important;
    border-radius: 0.8em !important;
    padding: 3em !important;
    max-width: 30em !important;
  }
}

@media screen and (max-width: 320px) {
  body {
    font-size: 10.75px;
  }
}
